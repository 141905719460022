import React from 'react';
import PropTypes from 'prop-types';
import { LanguageContextProvider, ProjectTypeNavigationProvider } from '../context';

const Container = ({ children }) => (
  <LanguageContextProvider>
    <ProjectTypeNavigationProvider>
      <div style={{ maxWidth: 1180, margin: '0 auto' }}>{children}</div>
    </ProjectTypeNavigationProvider>
  </LanguageContextProvider>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Container;
