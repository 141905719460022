import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import logo from '../../../static/logo.png';
import { LanguageContext, LanguageChangeContext } from '../../context';
import useMediaQuery from '../../hooks/useMediaQuery';
import styles from './header.module.css';

const Header = ({ withLang = true }) => {
  const locale = useContext(LanguageContext);
  const onChangeLocale = useContext(LanguageChangeContext);
  const onChangeLang = (newLocale) => {
    if (newLocale === locale) return;
    onChangeLocale(newLocale);
  };
  const isMobile = useMediaQuery('(max-width:480px)');

  return (
    <div className={styles.logo}>
      <div className={styles.link}>
        <Link to="/">
          <img src={logo} className={styles.link} alt="logo" />
        </Link>
      </div>
      {!isMobile && withLang && (
        <div className={styles.language}>
          <span
            className={`${styles.lang} ${locale === 'en-US' ? styles.currentLanguage : ''}`}
            onClick={() => onChangeLang('en-US')}
          >
            ENG
          </span>{' '}
          -{' '}
          <span
            className={`${styles.lang} ${locale === 'fr' ? styles.currentLanguage : ''}`}
            onClick={() => onChangeLang('fr')}
          >
            FR
          </span>
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  withLang: PropTypes.bool,
};
Header.defaultProps = {
  withLang: true,
};

export default Header;
