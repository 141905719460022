import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Container from '../../components/container';
import Header from '../../components/header';

import { LanguageContext } from '../../context';
import styles from './404.module.css';

function PageNotFound() {
  return (
    <Container>
      <LanguageContext.Consumer>
        {(localeFromReactContext) => {
          const pageNotFound =
            localeFromReactContext === 'fr' ? 'La page demandée est introuvable ' : 'Page not found ';
          const backToHome = localeFromReactContext === 'fr' ? "Retour à l'accueil" : 'Back to home';
          return (
            <>
              <Header withLang={false} />
              <Helmet title="404 - Page Introuvable" />
              <h1 className={styles.notFound}># 404 #</h1>
              <div className={styles.content}>
                <p>
                  {pageNotFound}-{' '}
                  <Link to="/" className={styles.link}>
                    {backToHome}
                  </Link>
                </p>
              </div>
            </>
          );
        }}
      </LanguageContext.Consumer>
    </Container>
  );
}

export default PageNotFound;
